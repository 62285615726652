import {inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';
import {DefaultStyle} from './storyblok-styling.service';

@Injectable({
  providedIn: 'root'
})
export class DynamicClassService {
  private doc = inject<Document>(DOCUMENT);


  i = 1;
  idNameOnStyleElement = 'storyblok-classes';
  mediaTypes = ['desktop', 'tablet', 'mobile'];

  createClass(data: any) {
    const keys = Object.keys(data);
    const mediaTypes = this.mediaTypes.filter(mediaType => keys.indexOf(mediaType) !== -1);
    const mapped = mediaTypes.map(key => this.parseData(data[key], key, this.i)).filter(d => !!d);
    const style = mapped.join('');
    const name = 'storyblok-class-' + this.i;
    if (style?.trim().length === 0) {
      return {name: undefined, style: undefined};
    }
    this.i++;

    return {name, style};
  }

  getCssMediaQuery(whichMediaQuery: string): string | undefined {
    // attach the mediaQuery string according to platform
    let mediaQuery;
    switch (whichMediaQuery) {
      case 'tablet':
        mediaQuery = '@media only screen and (max-width: 991px)';
        break;
      case 'mobile':
        mediaQuery = '@media only screen and (max-width: 575px)';
        break;
    }

    return mediaQuery;
  }

  // convert data to css
  parseData(data: any, whichMediaQuery: any, i: any) {
    // parse userData
    const content = Object.keys(data).reduce((p: any[], c: any) => {
      const value = data[c];
      p.push(`${c}: ${value} !important;`);
      return p;
    }, []).join('');
    if (!content) {
      return undefined;
    }

    const mediaQuery = this.getCssMediaQuery(whichMediaQuery);

    const name = 'storyblok-class-' + i;
    let style = '.' + name + ' {' + content + '}';

    if (mediaQuery) {
      style = mediaQuery + ' {' + style + '}';
    }
    return style;
  }

  appendClassToStyleElement(style: string) {
    const styleElement: any = this.doc.getElementById(this.idNameOnStyleElement);
    if (styleElement?.innerHTML.indexOf(style) !== -1) {
      return;
    }
    styleElement.innerHTML = styleElement.innerHTML + style;
  }

  generateCssClass(externalDataObject: any, defaults?: DefaultStyle): string | undefined {
    const newObject = this.convertExternalDataToObject(externalDataObject) ?? defaults;

    if (!newObject) {
      return undefined;
    }

    if (defaults) {
      const defaultsAny: any = defaults;
      this.mediaTypes.forEach(key => {
        // @ts-ignore
        newObject[key] = {...defaultsAny[key], ...newObject[key]};
      });
    }

    const {style, name} = this.createClass(newObject);
    if (!name || !style) {
      return undefined;
    }
    this.appendClassToStyleElement(style);
    return name;
  }

  convertExternalDataToObject(data: any) {
    if (!data || !data.styles) {
      return undefined;
    }
    const styles = data.styles;
    const cssObject: { [index: string]: any } = {tablet: {}, mobile: {}, desktop: {}};
    const devices = Object.keys(cssObject);
    const ignoreMachineName = ['size'];

    if (data.backgroundImage) {
      cssObject['desktop']['background-image'] = `url(${data.backgroundImage})`;
      cssObject['desktop']['background-repeat'] = 'no-repeat';
      cssObject['desktop']['background-size'] = 'cover';
      cssObject['desktop']['background-position'] = 'center';
    }

    if (data.backgroundColor) {
      cssObject['desktop']['background-color'] = data.backgroundColor;
    }

    for (const style of styles) {
      const measureType = style.type;
      const machineName = style.machineName;

      for (const device of devices) {
        const deviceObject = style[device];
        const deviceObjectKeys = Object.keys(deviceObject);
        for (const deviceObjectKey of deviceObjectKeys) {
          const value = deviceObject[deviceObjectKey];
          let cssKey = machineName + '-' + deviceObjectKey;

          if (!value) {
            continue;
          }

          if (style.machineName.indexOf(ignoreMachineName) !== -1) {
            cssKey = deviceObjectKey;
          }

          cssObject[device][cssKey] = value + (measureType ?? '');
        }
      }
    }
    return cssObject;
  }

}


